import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ImageRigaBee1 from './imageRigaBee1';

import "./header.css";

function Header ({ siteTitle }) {

  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav className="flex items-center justify-between flex-wrap bg-teal-500 p-1">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <div className="inline-block px-2" style={{ width: `65px`, height: `65px` }} >
          <ImageRigaBee1 class="fill-current h-8 w-8 mr-2" width="54" height="54" />
        </div>
        <Link to="/"  className="font-semibold text-xl tracking-tight">{ siteTitle }</Link>
      </div>

      <div className="block lg:hidden">
        <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-1 py-1 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>{`${ !isExpanded ? `Show` : `Hide` }`} Menu</title>
            <path className={`${ !isExpanded ? `block` : `hidden` }`} d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            <path className={`${ isExpanded ? `block` : `hidden` }`} d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"/>
          </svg>
        </button>
      </div>

      <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
        <div className="text-sm lg:flex-grow">
          <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Home
          </Link>
          <Link to="/events/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Events
          </Link>
          <Link to="/entertainers/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Entertainers
          </Link>
          <Link to="/producers/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Producers
          </Link>
          <Link to="/livestreaming/" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
            Live Streaming
          </Link>
        </div>

        <div>
          <a href="/login/" className="inline-block text-sm px-2 py-1 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0">
            Login
          </a>
        </div>
      </div>
    </nav>
  )
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;